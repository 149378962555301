<template>
    <div class="pick_date">

        <template v-if="month=='1'">
            <datepicker v-model="date" :minimumView="'month'" :maximumView="'month'" format="MMMM yyyy" :language="languages[language]"></datepicker>
        </template>
        <template v-else-if="disableDates">
            <datepicker :language="languages[language]" :disabledDates="disableDates" v-model="date" format="D d MMM yyyy" ></datepicker>
        </template>
        <template v-else>
            <datepicker :language="languages[language]" placeholder="Choisir une Date" v-model="date" format="D d MMM yyyy" ></datepicker>
        </template>

    </div>
</template>


<style lang="scss">
.pick_date{
    
}
</style>



<script>
import Datepicker from 'vuejs-datepicker'
import * as lang from 'vuejs-datepicker/src/locale'

export default {
    components:{
        Datepicker,
    },
    props:['value','month','disableDates'],
    data(){
        return{
            date    : this.value,
            language: "fr",
            languages: lang,
        }
    },
    watch:{
        $route (){
            this.date = this.value
        },
        date:function(){
            this.$emit('input', this.date)
        },
    },
    methods:{

    }
};

</script>